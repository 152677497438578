import { ModalsContext } from "@finbackoffice/site-core";
import classnames from "classnames";
import { useRouter } from "next/router";
import {
    cloneElement,
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    ReactNode,
    RefAttributes,
    RefObject,
    SyntheticEvent,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { createPortal } from "react-dom";
import FadeInAnimation, { FadeInAnimationVariantType } from "../fade-in/FadeInAnimation";
import styles from "./modal.module.sass";

interface IModalProps {
    children: ReactElement;
    styleClass?: string;
    type: string;
    animateVariant: FadeInAnimationVariantType;
    closeButton?: ReactNode;
    mask?: boolean;
    maskClosable?: boolean;
    keyboard?: boolean;
    onClose?: (e?: SyntheticEvent | KeyboardEvent) => void;
    position?: { top: number };
    priority?: boolean;
    containerElementId?: string;
}

export type IModalForwardRefProps = {
    open: () => void;
    close: () => void;
    setStyle?: (style: any) => void;
    isOpened: boolean;
};

const Modal: ForwardRefExoticComponent<IModalProps & RefAttributes<IModalForwardRefProps>> =
    forwardRef(
        (
            {
                styleClass,
                children,
                type,
                animateVariant,
                closeButton,
                onClose,
                mask = true,
                maskClosable = true,
                keyboard = true,
                position,
                priority,
                containerElementId,
            },
            ref,
        ) => {
            const [closing, setClosing] = useState<boolean>(false);
            const [priorityOpened, setPriorityOpened] = useState(false);
            const [containerElement, setContainerElement] = useState<HTMLElement | null>(null);
            const [inlineStyle, setInlineStyle] = useState({});
            const fadeInAnimationRef: RefObject<{
                clearVisible: () => void;
                forceOpen: () => void;
            }> = useRef(null);
            const timeoutRef = useRef<NodeJS.Timeout | null>(null);
            const router = useRouter();
            const modalRef = useRef<HTMLElement | null>(null);
            const touchStart = useRef(0);
            const isTouchMoving = useRef(false);
            const {
                setCurrentModal,
                currentModal,
                previousModal,
                clearCurrentModal,
                clearPrevModal,
            } = useContext(ModalsContext);
            const getContainerElement = useCallback(() => {
                let result = null;
                if (document) {
                    const id = containerElementId || (priority ? "modal-priority" : "modal-root");
                    result = document?.getElementById(id);

                    if (containerElementId && result === null) {
                        const newContainer = document.createElement("div");
                        newContainer.id = containerElementId;
                        document.body.appendChild(newContainer);
                        return newContainer;
                    }
                }
                return result;
            }, [containerElementId, priority]);

            const open = () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                setClosing(false);

                if (priority) {
                    setPriorityOpened(true);
                } else {
                    setCurrentModal(type);
                }
                if (closing) {
                    fadeInAnimationRef.current?.forceOpen();
                }
            };

            const close = useCallback(
                async (e?: SyntheticEvent) => {
                    if (!closing) {
                        setClosing(true);
                        if (router.query?.type === type) {
                            const query = { ...router.query };
                            delete query.type;
                            delete query.directory;
                            router.replace(
                                {
                                    query,
                                },
                                undefined,
                                { shallow: true },
                            );
                        }

                        fadeInAnimationRef.current?.clearVisible();

                        if (type === previousModal) {
                            if (timeoutRef.current) {
                                clearTimeout(timeoutRef.current);
                            }
                            timeoutRef.current = setTimeout(() => {
                                setClosing(false);
                                isTouchMoving.current = false;
                                clearPrevModal();
                                onClose?.(e);
                            }, 700);
                        } else if (type === currentModal) {
                            if (timeoutRef.current) {
                                clearTimeout(timeoutRef.current);
                            }
                            timeoutRef.current = setTimeout(() => {
                                setClosing(false);
                                isTouchMoving.current = false;
                                clearCurrentModal();
                                onClose?.(e);
                            }, 700);
                        } else if (priorityOpened) {
                            if (timeoutRef.current) {
                                clearTimeout(timeoutRef.current);
                            }

                            timeoutRef.current = setTimeout(() => {
                                setClosing(false);
                                isTouchMoving.current = false;
                                setPriorityOpened(false);
                                onClose?.(e);
                            }, 700);
                        }
                    }
                },
                [
                    closing,
                    router,
                    type,
                    previousModal,
                    currentModal,
                    priorityOpened,
                    onClose,
                    clearCurrentModal,
                    clearPrevModal,
                ],
            );

            const onKeydownHandler = useCallback(
                (e: KeyboardEvent) => {
                    if (e.keyCode === 27) {
                        if (priority) {
                            setPriorityOpened(false);
                        } else {
                            setCurrentModal(null);
                        }
                        onClose?.(e);
                    }
                },
                [onClose, priority, setCurrentModal],
            );

            useEffect(() => {
                if (currentModal === type || priorityOpened) {
                    document.addEventListener("keydown", onKeydownHandler, false);
                }

                return () => {
                    document.removeEventListener("keydown", onKeydownHandler, false);
                };
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [type]);

            useEffect(() => {
                if (previousModal && type === previousModal) {
                    close();
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [close, type]);

            useEffect(() => {
                setContainerElement(getContainerElement());

                return () => {
                    if (containerElementId) {
                        const element = document.getElementById(containerElementId);
                        element?.remove();
                    }
                };
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, []);

            useImperativeHandle(ref, () => ({
                open: () => open(),
                close: () => close(),
                setStyle: (style) => setInlineStyle(style),
                isOpened: type === (currentModal || previousModal) || priorityOpened,
            }));

            const onTouchStart: (event: any) => void = (event): void => {
                touchStart.current = event.touches[0].pageX;
            };

            const onTouchMove: (event: any) => void = useCallback(
                (event): void => {
                    if (
                        (animateVariant === "left" &&
                            touchStart.current - event.touches[0].pageX > 60) ||
                        (animateVariant === "right" &&
                            touchStart.current - event.touches[0].pageX < -60)
                    ) {
                        if (!isTouchMoving.current) {
                            isTouchMoving.current = true;
                            setCurrentModal(null);
                        }
                    }
                },
                [animateVariant, setCurrentModal],
            );

            useEffect(() => {
                const modalRefCurrent = modalRef.current;
                if (currentModal === type && keyboard) {
                    modalRefCurrent?.addEventListener("touchstart", onTouchStart);
                    modalRefCurrent?.addEventListener("touchmove", onTouchMove);
                } else if (modalRefCurrent) {
                    modalRefCurrent.removeEventListener("touchstart", onTouchStart);
                    modalRefCurrent.removeEventListener("touchmove", onTouchMove);
                }

                return () => {
                    if (modalRefCurrent && keyboard) {
                        modalRefCurrent.removeEventListener("touchstart", onTouchStart);
                        modalRefCurrent.removeEventListener("touchmove", onTouchMove);
                    }
                };
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [currentModal, type]);

            const onBackdropClick = useCallback(() => {
                if (maskClosable) {
                    if (priority) {
                        setPriorityOpened(false);
                    } else {
                        setCurrentModal(null);
                    }
                }
            }, [maskClosable, priority, setCurrentModal]);

            if (currentModal === type || previousModal === type || priorityOpened) {
                return containerElement
                    ? createPortal(
                          <FadeInAnimation ref={fadeInAnimationRef} variant={animateVariant}>
                              <section
                                  style={position ? { top: position.top } : undefined}
                                  className={classnames(styles.modalContainer, styleClass)}
                                  data-testid="modal">
                                  {mask && (
                                      <div
                                          data-testid="modal-backdrop"
                                          className={styles.modalBackdrop}
                                          onClick={onBackdropClick}
                                      />
                                  )}
                                  <section className="modal" ref={modalRef} style={inlineStyle}>
                                      {closeButton &&
                                          (typeof closeButton === "boolean" ? (
                                              <i
                                                  className={styles.closeModal}
                                                  onClick={close}
                                                  data-testid="modal-close-button"
                                              />
                                          ) : (
                                              closeButton
                                          ))}
                                      {typeof children.type !== "string"
                                          ? cloneElement(children, { close })
                                          : children}
                                  </section>
                              </section>
                          </FadeInAnimation>,
                          containerElement,
                      )
                    : null;
            }

            return null;
        },
    );

export default Modal;
