import {
    useState,
    useEffect,
    useCallback,
    useContext,
    ComponentProps,
    SyntheticEvent,
    useMemo,
    CSSProperties,
    FC,
} from "react";
import Image from "next/image";
import classnames from "classnames";
import { LocaleContext, useRuntimeConfig } from "@finbackoffice/site-core";

type ImageProps = Omit<ComponentProps<typeof Image>, "src">;

export interface ImgProps extends ImageProps {
    source: string;
    alt: string;
    fallbackSrc?: string;
    wrapperClassName?: string;
    style?: CSSProperties;
    onFallback?: () => void;
}

const Img: FC<ImgProps> = ({
    source,
    fallbackSrc,
    wrapperClassName,
    alt,
    style,
    onFallback,
    ...rest
}) => {
    const { locale } = useContext(LocaleContext);
    const [imgSrc, setImgSrc] = useState(source);
    const [ready, setReady] = useState(false);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");

    const handleError = useCallback(() => {
        if (fallbackSrc) {
            setImgSrc(fallbackSrc);
            onFallback?.();
        } else if (source.includes(locale)) {
            setImgSrc(source.replace(locale, COMMON_SITE_CONFIGS.defaultLocale));
        }
    }, [fallbackSrc, source, locale, onFallback, COMMON_SITE_CONFIGS.defaultLocale]);

    const onLoadCallback = useCallback(() => setReady(true), []);

    useEffect(() => {
        setImgSrc(source);
    }, [source]);

    const handleOnLoad = (e: SyntheticEvent<HTMLImageElement>) => {
        onLoadCallback();
        rest.onLoad?.(e);
    };

    const inlineStyles = useMemo(() => {
        const styles = { ...style, opacity: ready ? 1 : 0 };

        if (!rest.priority) {
            styles.transition = "opacity 0.2s";
        }

        return style;
    }, [ready, rest.priority, style]);

    return (
        <span className={classnames("imageNext", wrapperClassName)}>
            <Image
                quality={100}
                alt={alt}
                src={imgSrc}
                onError={handleError}
                style={inlineStyles}
                unoptimized
                {...rest}
                onLoad={handleOnLoad}
            />
        </span>
    );
};

export default Img;
