import { CasinoGameTag, Language } from "@finbackoffice/enums";
import {
    formatAppNameText,
    BonusPagesEnum,
    CasinoGameTypeEnum,
    CasinoLayoutEnum,
    IBonusPagesConfig,
    IRuntimeConfig,
    sortById,
    BannerSizeEnum,
} from "@finbackoffice/fe-core";
import { BrowserStorageIdsEnum, CasinoScopesMap } from "@finbackoffice/site-core";
import { DEFAULT_ROOT_LOCALE } from "@finbackoffice/site-server-core";
import {
    ICategoryFeedResponse,
    IEventFeedResponse,
    ISportFeedResponse,
} from "@finbackoffice/websocket-client";
import { RouteNames, RouterQuery } from "./constants";

export const getCategoriesEventsBelongTo = (
    categories: ICategoryFeedResponse[],
    amount: number,
): number[] => {
    const result: number[] = [];

    let count = 0;
    for (let i = 0; i < categories.length; i++) {
        const category = categories[i];
        count += category.games_count;
        result.push(category.id);
        if (count >= amount) {
            break;
        }
    }
    return result;
};

type GetCasinoGameImgSrcOptions = {
    showVideo?: boolean;
    imgVariant?: string;
    useAppName?: boolean;
};

export const getCasinoGameImgSrc = (
    runtimeConfig: IRuntimeConfig,
    gameId: string,
    gameType: CasinoGameTypeEnum | "megaways" | "",
    tags: CasinoGameTag[],
    options?: GetCasinoGameImgSrcOptions,
) => {
    let gameImgId = gameId.replace("-mobile", "");
    let dir = `${runtimeConfig.ASSETS_URL}/common/casino`;
    let extention;

    if (runtimeConfig.SITE_CONFIG.layouts._casino.type === CasinoLayoutEnum.Compact) {
        extention = tags.includes(CasinoGameTag.VFX) ? "mp4" : "webp";
        dir += "/compact";

        if (CasinoScopesMap.slots.includes(gameType as CasinoGameTypeEnum)) {
            dir += "/slot-games";
        } else if (gameType === "megaways") {
            dir += `/slot-games/megaways`;
        } else {
            dir += `/live-casino-games`;
        }

        if (options?.showVideo) {
            if (options.imgVariant) {
                gameImgId += `_${options.imgVariant}`;
            }
            extention = "mp4";
        }
    }

    return `${dir}/${gameImgId}${
        options?.useAppName
            ? `_${formatAppNameText(runtimeConfig.COMMON_SITE_CONFIGS.appName)}`
            : ""
    }.${extention}`;
};

export const getCurrentLocale = (
    runtimeConfig: IRuntimeConfig,
    currentLocale: string | undefined,
) => {
    currentLocale = currentLocale || runtimeConfig.COMMON_SITE_CONFIGS.defaultLocale;

    if (currentLocale === DEFAULT_ROOT_LOCALE) {
        return runtimeConfig.COMMON_SITE_CONFIGS.defaultLocale;
    }
    return currentLocale as Language;
};

export function generateUUID() {
    if (typeof window === "undefined") {
        // server
        return "";
    }

    // browser
    const localStorageValue = localStorage.getItem(BrowserStorageIdsEnum.DEVICE_ID);
    if (localStorageValue) {
        return localStorageValue;
    }
    // @ts-expect-error TS(2365): Operator '+' cannot be applied to types 'number[]'... Remove this comment to see the full error message
    const uuid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
        /[018]/g,
        (
            c: any, // eslint-disable-next-line no-bitwise
        ) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    );
    localStorage.setItem(BrowserStorageIdsEnum.DEVICE_ID, uuid);
    return uuid;
}

export const getBonusesDefaultRoute = (config?: IBonusPagesConfig) => {
    let result = `${RouteNames.PROMOTIONS}/${RouterQuery.Casino}`;
    switch (config?.name) {
        case BonusPagesEnum.Referral:
            result = RouteNames.REFERRAL;
            break;
        case BonusPagesEnum.Cashback:
            result = RouteNames.CASHBACK;
            break;
        case BonusPagesEnum.Promotions:
            result = RouteNames.PROMOTIONS;
            break;
        case BonusPagesEnum.Casino:
            result = `${RouteNames.PROMOTIONS}/${RouterQuery.Casino}`;
            break;
        case BonusPagesEnum.Casino2:
            result = `${RouteNames.PROMOTIONS}/${RouterQuery.Casino2}`;
            break;
        case BonusPagesEnum.Promo2:
            result = `${RouteNames.PROMOTIONS}/${RouterQuery.Promo2}`;
            break;
        case BonusPagesEnum.Promo3:
            result = `${RouteNames.PROMOTIONS}/${RouterQuery.Promo3}`;
            break;
        case BonusPagesEnum.Promo4:
            result = `${RouteNames.PROMOTIONS}/${RouterQuery.Promo4}`;
            break;
        case BonusPagesEnum.Promo5:
            result = `${RouteNames.PROMOTIONS}/${RouterQuery.Promo5}`;
            break;
        case BonusPagesEnum.Promo6:
            result = `${RouteNames.PROMOTIONS}/${RouterQuery.Promo6}`;
            break;
        default:
            result = `${RouteNames.PROMOTIONS}/${RouterQuery.Casino}`;
    }
    return result;
};

export const convertGamesToSports = (games: IEventFeedResponse[]): ISportFeedResponse[] => {
    const sportsData: ISportFeedResponse[] = [];

    for (let i = 0; i < games.length; ++i) {
        const game = games[i];
        const sportIndex = sportsData.findIndex((sport) => sport.id === game.sport_id);

        if (sportIndex === -1) {
            const sportData: ISportFeedResponse = {
                id: game.sport_id,
                name: game.sport_name,
                slug: game.sport_slug,
                games_count: 1,
                categories: [],
            };

            sportsData.push(sportData);
        } else {
            sportsData[sportIndex].games_count++;
        }
    }

    return sportsData.sort(sortById);
};

export const getBannerSize = (size: BannerSizeEnum) => {
    switch (size) {
        case BannerSizeEnum.Medium:
            return {
                width: 360,
                height: 220,
            };
        case BannerSizeEnum.Narrow:
            return {
                width: 414,
                height: 100,
            };
        default:
            return {
                width: 414,
                height: 450,
            };
    }
};
